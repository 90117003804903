.technology-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.technology-hero {
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.technology-container01 {
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.technology-text {
  color: var(--dl-color-arcobo-arcobo_1);
}
.technology-text01 {
  font-size: 1.5em;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat-Regular";
  font-weight: 400;
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.technology-container02 {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.technology-image {
  width: auto;
  height: auto;
  object-fit: cover;
}
.technology-text13 {
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-hero1 {
  width: 100%;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.technology-container03 {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.technology-image1 {
  width: auto;
  height: auto;
  object-fit: cover;
}
.technology-text14 {
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-container04 {
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.technology-text15 {
  color: var(--dl-color-arcobo-arcobo_1);
}
.technology-text16 {
  font-size: 1.5rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat-Regular";
  font-weight: 400;
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.technology-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.technology-text19 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  text-align: center;
  font-family: "Montserrat-ExtraBold";
}
.technology-text20 {
  font-size: 1.5rem;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
.technology-image2 {
  width: auto;
  margin: var(--dl-space-space-halfunit);
  object-fit: cover;
}
.technology-text21 {
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.technology-text22 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  text-align: center;
  font-family: "Montserrat-ExtraBold";
}
.technology-text23 {
  font-size: 1.5rem;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
.technology-gallery {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.technology-gallery1 {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.technology-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/images/waammat-1400w.png");
  background-position: center;
}
.technology-container05:hover {
  transform: scale(0.98);
}
.technology-text37 {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  align-self: flex-end;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: contain;
  justify-content: flex-end;
  background-image: url("/images/mx3d_tube-1200h.png");
  background-repeat: space;
  background-position: center;
}
.technology-container06:hover {
  transform: scale(0.98);
}
.technology-text38 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  align-self: center;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/images/lincolnelectric-1200h.png");
  background-repeat: space;
  background-position: center;
}
.technology-container07:hover {
  transform: scale(0.98);
}
.technology-text39 {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  align-self: flex-end;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/images/hydroreview-1400w.png");
  background-position: center;
}
.technology-container08:hover {
  transform: scale(0.98);
}
.technology-text40 {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  align-self: flex-end;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/images/weibo-1200h.png");
  background-position: center;
}
.technology-container09:hover {
  transform: scale(0.98);
}
.technology-text41 {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  align-self: flex-end;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.technology-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/images/andritz-1200h.png");
  background-repeat: space;
  background-position: center;
}
.technology-container10:hover {
  transform: scale(0.98);
}
.technology-text42 {
  color: #ffffff;
  font-size: 1rem;
  align-self: flex-end;
  text-align: center;
  font-family: "Montserrat-Regular";
}
@media(max-width: 991px) {
  .technology-hero {
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .technology-container01 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .technology-text {
    text-align: center;
  }
  .technology-text01 {
    text-align: center;
  }
  .technology-text02 {
    text-align: center;
  }
  .technology-hero1 {
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .technology-container04 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .technology-text15 {
    text-align: center;
  }
  .technology-text16 {
    text-align: center;
  }
  .technology-text20 {
    max-width: 100%;
  }
  .technology-text23 {
    max-width: 100%;
  }
  .technology-gallery1 {
    grid-gap: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .technology-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .technology-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .technology-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .technology-text20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .technology-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .technology-text23 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .technology-gallery1 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .technology-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .technology-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .technology-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .technology-container04 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .technology-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .technology-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .technology-text23 {
    font-size: 1.5rem;
    font-family: Montserrat-Regular;
  }
  .technology-gallery1 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
}
