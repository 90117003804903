.gallery-card1-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.gallery-card1-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-card1-text {
  color: var(--dl-color-arcobo-arcobo_1);
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Montserrat-Regular";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery-card1-text1 {
  color: #000000;
  align-self: flex-start;
  font-family: "Montserrat-Regular";
}



@media(max-width: 767px) {
  .gallery-card1-gallery-card {
    flex-direction: column;
  }
}
