.footer-arcobo-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-arcobo-container {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.footer-arcobo-navlink {
  display: contents;
}
.footer-arcobo-image {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.footer-arcobo-text {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.footer-arcobo-icon-group {
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-arcobo-link {
  display: contents;
}
.footer-arcobo-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-arcobo-link1 {
  display: contents;
}
.footer-arcobo-icon2 {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.footer-arcobo-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-arcobo-container1 {
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-arcobo-container2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-arcobo-text07 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-arcobo-text08 {
  margin-bottom: var(--dl-space-space-unit);
}
.footer-arcobo-text09 {
  margin-bottom: var(--dl-space-space-unit);
}
.footer-arcobo-container3 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-arcobo-text10 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-arcobo-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.footer-arcobo-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.footer-arcobo-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.footer-arcobo-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-arcobo-container5 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.footer-arcobo-text14 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-arcobo-text15 {
  margin-bottom: var(--dl-space-space-unit);
}
.footer-arcobo-navlink1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-arcobo-text16 {
  margin-bottom: var(--dl-space-space-unit);
}




@media(max-width: 991px) {
  .footer-arcobo-footer {
    flex-direction: column;
  }
  .footer-arcobo-container {
    align-items: center;
  }
  .footer-arcobo-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .footer-arcobo-container1 {
    margin-right: 0px;
  }
}
@media(max-width: 767px) {
  .footer-arcobo-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-arcobo-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-arcobo-container1 {
    width: 100%;
    justify-content: space-between;
  }
  .footer-arcobo-container3 {
    margin-right: 0px;
  }
  .footer-arcobo-container4 {
    margin-top: var(--dl-space-space-twounits);
  }
  .footer-arcobo-container5 {
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .footer-arcobo-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-arcobo-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-arcobo-container1 {
    align-items: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .footer-arcobo-container2 {
    margin-right: var(--dl-space-space-unit);
  }
  .footer-arcobo-container3 {
    align-self: stretch;
  }
}
