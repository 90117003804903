.navigation-links-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-text {
  font-family: "Montserrat-Regular";
  text-decoration: none;
}
.navigation-links-navlink {
  font-family: "Montserrat-Regular";
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-text1 {
  font-family: "Montserrat-Regular";
  margin-left: var(--dl-space-space-twounits);
}
.navigation-links-text2 {
  font-family: "Montserrat-Regular";
  margin-left: var(--dl-space-space-twounits);
}
.navigation-links-text3 {
  font-family: "Montserrat-Regular";
  margin-left: var(--dl-space-space-twounits);
}
.navigation-links-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  justify-content: center;
}
.navigation-links-text4 {
  align-self: center;
  font-family: "Montserrat-Regular";
  margin-right: var(--dl-space-space-halfunit);
}
.navigation-links-select {
  flex: 0;
  align-self: center;
  font-family: ""Montserrat-Regular"";
}










@media(max-width: 1200px) {
  .navigation-links-container {
    align-items: center;
  }
  .navigation-links-select {
    align-self: center;
  }
}
@media(max-width: 991px) {
  .navigation-links-container {
    align-self: center;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .navigation-links-nav {
    flex-direction: column;
  }
  .navigation-links-text {
    font-size: 1.5rem;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-navlink {
    font-size: 1.5rem;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-text1 {
    font-size: 1.5rem;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-text2 {
    font-size: 1.5rem;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-text3 {
    font-size: 1.5rem;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-container {
    margin-left: var(--dl-space-space-halfunit);
  }
  .navigation-links-text4 {
    font-size: 1.5rem;
  }
  .navigation-links-select {
    font-size: 1.5rem;
  }
}
