.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  align-self: center;
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  background-size: cover;
  justify-content: flex-end;
  background-image: radial-gradient(circle at left, rgba(0, 0, 0, 0.8) 0.00%,rgba(0, 0, 0, 0.8) 8.00%,rgba(110, 35, 131, 0.8) 76.00%),url("/images/background-1500h.png");
  background-position: top left,center;
}
.home-heading {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 5rem;
  align-self: flex-start;
  font-family: "Montserrat-ExtraBold";
  text-transform: capitalize;
}
.home-btn-group {
  flex: 0;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  animation-name: none;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-start;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-link {
  color: var(--dl-color-gray-black);
  margin: var(--dl-space-space-unit);
  padding: var(--dl-space-space-unit);
  font-size: 1rem;
  align-self: center;
  font-style: normal;
  transition: 0.3s;
  font-family: "Montserrat-Regular";
  font-weight: 700;
  padding-top: var(--dl-space-space-twounits);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  text-transform: uppercase;
  text-decoration: none;
}
.home-link:hover {
  transform: scale(1.02);
}
.home-btn-group1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  animation-name: none;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: flex-start;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-text {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 1.5rem;
  font-family: "Montserrat-Regular";
  font-weight: 400;
  line-height: 150%;
}
.home-hero1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container1 {
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text01 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  font-family: "Montserrat-Regular";
}
.home-text02 {
  font-size: 1.5rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  font-family: "Montserrat-Regular";
  font-weight: 400;
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text03 {
  font-family: "Montserrat-Regular";
}
.home-image {
  width: 411px;
  height: 624px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text05 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text06 {
  color: rgb(0, 0, 0);
  width: 80%;
  font-size: 1.5rem;
  text-align: left;
  font-family: "Montserrat-Regular";
}
.home-container2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: center;
  margin-top: var(--dl-space-space-fourunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-features1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text07 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text10 {
  color: #000000;
  width: 80%;
  font-size: 1.5rem;
  text-align: left;
  font-family: "Montserrat-Regular";
}
.home-features2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.home-text11 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
.home-separator {
  width: 100px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}
.home-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.home-container5 {
  width: 450px;
  height: 450px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
  background-size: cover;
  justify-content: center;
  background-image: url("/images/relativity-500h.png");
  background-repeat: repeat;
  background-position: left;
}
.home-text14 {
  left: 0px;
  color: #ffffff;
  right: 0px;
  bottom: 0px;
  margin: var(--dl-space-space-unit);
  position: absolute;
  font-size: 12px;
  align-self: flex-end;
  text-align: center;
  font-family: "Montserrat-Regular";
}
.home-text15 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
.home-gallery {
  width: 100%;
  display: grid;
  padding: var(--dl-space-space-threeunits);
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  grid-template-columns: 1fr 1fr 1fr;
}
.home-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-container6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-text18 {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 3rem;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat-Regular";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text19 {
  font-size: 1.5rem;
  font-family: "Montserrat-Regular";
  margin-right: var(--dl-space-space-threeunits);
}
.home-image1 {
  width: 719px;
  height: 356px;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
}
@media(max-width: 991px) {
  .home-hero {
    height: 80vh;
    flex-direction: column;
  }
  .home-heading {
    font-size: 3.5rem;
  }
  .home-hero1 {
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-container1 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text01 {
    text-align: center;
  }
  .home-text02 {
    text-align: center;
  }
  .home-image {
    order: 2;
  }
  .home-text05 {
    text-align: center;
  }
  .home-text06 {
    text-align: center;
  }
  .home-container2 {
    grid-template-columns: 1fr 1fr;
  }
  .home-text07 {
    text-align: center;
  }
  .home-text10 {
    text-align: center;
  }
  .home-features2 {
    align-items: center;
  }
  .home-container3 {
    flex-direction: column;
  }
  .home-container5 {
    width: 300px;
    height: 300px;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }
  .home-text15 {
    text-align: center;
  }
  .home-gallery {
    grid-template-columns: 1fr 1fr;
  }
  .home-banner {
    flex-direction: column;
  }
  .home-container6 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text19 {
    text-align: left;
    margin-left: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 100%;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image {
    width: 80%;
  }
  .home-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text05 {
    text-align: center;
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text07 {
    text-align: center;
  }
  .home-features2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text11 {
    text-align: center;
  }
  .home-container3 {
    flex-direction: column;
  }
  .home-text15 {
    text-align: center;
  }
  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text19 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-image1 {
    width: 100%;
    height: 100%;
  }
}
@media(max-width: 479px) {
  .home-hero {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    min-height: auto;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-btn-group1 {
    flex-direction: column;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container2 {
    grid-template-columns: 1fr;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-features2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container4 {
    grid-template-columns: 1fr;
  }
  .home-container5 {
    width: 250px;
    height: 250px;
  }
  .home-gallery {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
