.header-arcobo-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.header-arcobo-header {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 100%;
  min-height: auto;
  align-items: center;
  justify-content: space-between;
}
.header-arcobo-navlink {
  display: contents;
}
.header-arcobo-image {
  width: auto;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.header-arcobo-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: row;
}
.header-arcobo-nav {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.header-arcobo-root-class-name {
  top: 0px;
  left: 0px;
  height: auto;
  position: static;
  align-self: center;
}


@media(max-width: 1200px) {
  .header-arcobo-header {
    height: auto;
    flex-direction: column;
  }
  .header-arcobo-container1 {
    margin-top: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .header-arcobo-image {
    width: 40vh;
  }
  .header-arcobo-container1 {
    flex-direction: column;
  }
  .header-arcobo-nav {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .header-arcobo-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .header-arcobo-header {
    padding: var(--dl-space-space-unit);
  }
}
