.feature-card3-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.feature-card3-icon {
  fill: var(--dl-color-gray-500);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: none;
}
.feature-card3-image {
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xlarge);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card3-text {
  color: var(--dl-color-arcobo-arcobo_1);
  font-size: 2rem;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Montserrat-Regular";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card3-text1 {
  color: #000000;
  font-size: 1.1rem;
  text-align: center;
  font-family: "Montserrat-Regular";
}




