.privacypolicy-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.privacypolicy-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.privacypolicy-text {
  font-size: 3rem;
  text-align: center;
  font-family: "Montserrat-ExtraBold";
}
.privacypolicy-text01 {
  font-size: 1.5rem;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  font-family: "Montserrat-Regular";
  margin-bottom: var(--dl-space-space-twounits);
}
@media(max-width: 991px) {
  .privacypolicy-text01 {
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .privacypolicy-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .privacypolicy-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .privacypolicy-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
