.feature-card1-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card1-image {
  width: 200px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card1-text {
  color: var(--dl-color-arcobo-arcobo_1);
  font-family: "Montserrat-Regular";
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card1-text1 {
  color: var(--dl-color-gray-black);
  font-family: "Montserrat-Regular";
}
.feature-card1-root-class-name {
  width: 100%;
}




@media(max-width: 479px) {
  .feature-card1-feature-card {
    align-items: center;
    justify-content: flex-start;
  }
  .feature-card1-text {
    font-style: normal;
    font-weight: 600;
  }
}
